










































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import PatientDataItem from '@/components/PatientDataItem.vue';
import { IPatient } from '@/interfaces/patients';
import { readUserProfile } from '@/store/main/getters';

@Component({
  components: {
    PatientDataItem,
  },
})
export default class PatientData extends Vue {
  @Prop({ type: Object }) public patient: IPatient | undefined;
  @Prop({ type: Boolean }) public noTitle: boolean | undefined;
  @Prop({ type: [Boolean] }) public priority: boolean;

  get userProfile() {
      return readUserProfile(this.$store);
  }

  /**
   * Util getter to check if the user
   * is super user. That mean that is able to see the full patient information
   */
  get showFullPatientInformation() {
    return !this.isReviewer && (this.userProfile?.isSuperuser || this.priority);
  }

  get isReviewer() {
    return this.userProfile?.isReviewer;
  }

  get diet() {
    if (this.patient && this.patient.patientBehaviorInfo
    && this.patient.patientBehaviorInfo.diet && this.patient.patientBehaviorInfo.diet.length > 0) {
      return this.patient.patientBehaviorInfo.diet;
    } else {
      return ['N/A'];
    }
  }

  get patientID() {
    if (this.patient && this.patient.patientIdentifier) {
      return this.patient.patientIdentifier;
    } else {
      return 'N/A';
    }
  }

  get surgeries() {
    if (this.patient && this.patient.medicalHx && this.patient.medicalHx.surgeries
      && this.patient.medicalHx.surgeries.length > 0) {
      return this.patient.medicalHx.surgeries;
    } else {
      return ['N/A'];
    }
  }

  get hospitalizations() {
    if (this.patient && this.patient.medicalHx
          && this.patient.medicalHx.hospitalizations && this.patient.medicalHx.hospitalizations.length > 0) {
      return this.patient.medicalHx.hospitalizations;
    } else {
      return ['N/A'];
    }
  }

  get medications() {
    if (this.patient && this.patient.medicalHx
          && this.patient.medicalHx.currentMedications && this.patient.medicalHx.currentMedications.length > 0) {
      return this.patient.medicalHx.currentMedications;
    } else {
      return ['N/A'];
    }
  }

    get chronicConditions() {
    if (this.patient && this.patient.medicalHx && this.patient.medicalHx.chronicConditions
          && this.patient.medicalHx.chronicConditions.length > 0) {
      return this.patient.medicalHx.chronicConditions;
    } else {
      return ['N/A'];
    }
  }

  /**
   * calculates if female section should be shown
   * by checking if there is patient and if biological sex is "female"
   */
  get showFemaleSection() {
    if (this.patient && this.patient.biologicalSex) {
      if (this.patient.biologicalSex.toUpperCase() === 'FEMALE') {
        return this.patient.femaleReproductiveHealth || false; // only returning true if there is data
      }
    } else {
      return false;
    }
  }

  /**
   * Calculates if the birthControl info should be shown
   * it depends if the patient is female and if the currentlyOnBirthControl flag is turned on
   */
  get showFemaleBirthControlInfo() {
    if (this.showFemaleSection && this.patient!.femaleReproductiveHealth!.currentlyOnBirthControl) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Calculates if the prior pregnancies information should be shown
   * it depends on if the patient is Female and also if the priorPregnancies flag is turned on
   */
  get showFemalePriorPregnanciesInfo() {
    if (this.showFemaleSection && this.patient!.femaleReproductiveHealth!.priorPregnancies &&
      this.patient!.femaleReproductiveHealth?.priorPregnancy
      && Object.keys(this.patient!.femaleReproductiveHealth?.priorPregnancy).length > 0) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Calculates birth control type
   * checking if the patient is female and if there is data
   */
  get birthControlType() {
    if (this.showFemaleSection && this.patient?.femaleReproductiveHealth?.birthControlType) {
      return this.patient.femaleReproductiveHealth.birthControlType.length > 0
        ? (this.patient.femaleReproductiveHealth.birthControlType as string []).join(';') : 'N/A';
    } else {
      return 'N/A';
    }
  }

  /**
   * Calculates if the patient has information for currentMenstrualStatus
   * also it checks if the patient is female
   */
  get currentMenstrualStatus() {
    if (this.showFemaleSection && this.patient?.femaleReproductiveHealth?.currentMenstrualStatus) {
      return this.patient.femaleReproductiveHealth.currentMenstrualStatus.length > 0
        ? this.patient.femaleReproductiveHealth.currentMenstrualStatus : 'N/A';
    } else {
      return 'N/A';
    }
  }

  get priorPregnanciesCount() {
    if (this.showFemalePriorPregnanciesInfo) {
      return this.patient!.femaleReproductiveHealth!.priorPregnancy!.count;
    } else {
      return 0;
    }
  }

  get numberOfChildren() {
    if (this.showFemalePriorPregnanciesInfo) {
      return this.patient!.femaleReproductiveHealth!.priorPregnancy!.numberOfChildren;
    } else {
      return 0;
    }
  }

  get numberOfMiscarriages() {
    if (this.showFemalePriorPregnanciesInfo) {
      return this.patient!.femaleReproductiveHealth!.priorPregnancy!.numberOfMiscarriages;
    } else {
      return 0;
    }
  }

  get numberOfAbortions() {
    if (this.showFemalePriorPregnanciesInfo) {
      return this.patient!.femaleReproductiveHealth!.priorPregnancy!.numberOfAbortions;
    } else {
      return 0;
    }
  }

  /**
   * Gets the patient name based on the patient data and
   * current user privileges.
   */
  get name() {
    if (this.patient) {
        if (this.patient.fname && this.patient?.lname) {
            if (this.showFullPatientInformation) {
              return this.patient.fname + ' ' + this.patient.lname;
            } else {
              return this.patient.fname[0] + '. ' + this.patient.lname;
            }
        } else if (this.patient.fname && !this.patient.lname) {
              return this.patient.fname;
          } else if (!this.patient.fname && this.patient.lname) {
              return this.patient.lname;
          } else {
              return 'N/A';
        }
    }
    return '';
  }

  /**
   * Gets the date of birth based on the patient data and
   * current user privileges
   * If the user is admin it will just return the date of birth
   * otherwise it will return the age of the patient
   */
  get dateOfBirth() {
    if (this.patient && this.patient.dob) {
      if (this.showFullPatientInformation) {
        return this.patient.dob;
      } else {
        // calculates the age of the patient
        const age = new Date().getFullYear() - new Date(this.patient.dob).getFullYear();
        return age + ' years old';
      }
    } else {
      return 'N/A';
    }
  }

  get address() {
    if (this.patient && this.patient.patientContactInfo) {
      if (this.patient.patientContactInfo.city && this.patient.patientContactInfo.state) {
        return this.patient.patientContactInfo.city + ', ' + this.patient.patientContactInfo.state;
      } else if (this.patient.patientContactInfo.city) {
        return this.patient.patientContactInfo.city;
      } else if (this.patient.patientContactInfo.state) {
        return this.patient.patientContactInfo.state;
      } else if (this.patient.patientContactInfo.streetAddress) {
        return this.patient.patientContactInfo.streetAddress;
      } else {
        return 'N/A';
      }
    } else {
      return 'N/A';
    }
  }

  /**
   * Gets the patient email based on the patient data.
   * If the user is a super user, then it will return the email address
   * if the user is not a super user, then it will return an empty string
   */
  get email() {
    if (this.patient && this.patient.patientContactInfo && this.patient.patientContactInfo.email) {
      if (this.showFullPatientInformation) {
        return this.patient.patientContactInfo.email;
      } else {
        return '';
      }
    } else {
      return 'N/A';
    }
  }

  get biologicalSex() {
    if (this.patient && this.patient.biologicalSex) {
        return this.patient.biologicalSex;
    } else {
        return 'N/A';
    }
  }

  get phoneNumber() {
      if (this.patient && this.patient.patientContactInfo && this.patient.patientContactInfo.phoneNumber) {
          return this.patient.patientContactInfo.phoneNumber;
      } else {
          return 'N/A';
      }
  }
}
