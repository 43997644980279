





































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class PatientData extends Vue {
  @Prop({ type: String }) public title: string;
  @Prop({ type: [String, Array] }) public content: string | string[];

  get simplifiedContent() {
    if (Array.isArray(this.content)) {
      return this.content.join(', ');
    }
    return this.content;
  }

  get expandedContent() {
    return this.content;
  }

  get isArray() {
    return Array.isArray(this.content);
  }
}
